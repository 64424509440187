<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="10">
          <v-btn right color="primary" class="mb-4 me-3" @click.stop="isAddActive = !isAddActive">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Address</span>
          </v-btn></v-col
        >
        <v-col cols="2"> </v-col>
      </v-row>
      <add-address
        @refetch-data="fetchAddressess"
        :BranchId="parseInt($router.currentRoute.params.id)"
        v-model="isAddActive"
      ></add-address>
      <address-delete @refetch-data="fetchAddressess" :Id="addressTemp.Id" v-model="isDialogDelete" />
      <address-edit @refetch-data="fetchAddressess" :Address="addressTemp" v-model="isDialogEdit" />

      <!-- Main Card -->
      <app-card-actions @refresh="fetchAddressess">
        <template slot="title"> Address List </template>
      <!-- Search --      server-items-length="totalAddressListTable"     :options.sync="options"
            : -->

        <v-card-text>
          <v-text-field
            v-model="search"
            single-line
            dense
            outlined
            hide-details
            placeholder="Search"
            class="Orders-List-search me-3"
          ></v-text-field>
          <v-data-table
            :headers="tableColumns"
            :items="AddressListTable"
            :search="search"

            :loading="loading"
          >
            <template #[`item.Id`]="{ item }" style="align: 'start' " > #{{ item.Id }} </template>

            <template #[`item.Price`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Price != undefined? item.Price.toFixed(3) : 0 }} JD</span>
              </div>
            </div>
          </template>
          <template #[`item.OrdersNumber`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.OrdersNumber != undefined?  item.OrdersNumber.length : 0 }}</span>
              </div>
            </div>
          </template>
          <template #[`item.CustomerNumber`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{  item.CustomerNumber  }}</span>
              </div>
            </div>
          </template>
            <!-- status -->
            <template #[`item.Status`]="{ item }">
              <v-chip
                small
                :color="
                  $store.state.status['addresses'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                "
                :class="`${
                  $store.state.status['addresses'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                }--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.Status }}
              </v-chip>
            </template>
            <!-- Actions -->
            <template #[`item.Actions`]="{ item }">
              <div class="demo-space-x">
                <v-btn
                v-if="item.Status!='inactive'"
                  icon
                  color="error"
                  @click.stop=";(isDialogDelete = !isDialogDelete), (addressTemp = { ...item })"
                >
                  <v-icon small>
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
                <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (addressTemp = { ...item })">
                  <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </app-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AddAddress from './AddAddress.vue'
import AddressDelete from './AddressDelete.vue'
import AddressEdit from './AddressEdit.vue'
import useAddressList from './useAddressList.js'

export default {
  components: {
    AddAddress,
    AppCardActions,
    AddressEdit,
    AddressDelete,
  },
  setup() {
    const isAddActive = ref(false)
    const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    const addressTemp = ref({ Id: 0 })

    const {
      AddressListTable,
      tableColumns,
      search,
      totalAddressListTable,
      options,
      addressTotalLocal,
      fetchAddressess,
      loading,
    } = useAddressList()

    return {
      addressTemp,
      isAddActive,
      isDialogDelete,
      isDialogEdit,
      AddressListTable,
      tableColumns,
      search,
      totalAddressListTable,
      options,
      addressTotalLocal,
      fetchAddressess,
      loading,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
