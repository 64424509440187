import router from '@/router'
import { Get } from '@core/api/Address'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useAddressList() {
  const tableColumns = [
    { text: 'Id', value: 'Id', filterable: false, },
    { text: 'Name', value: 'Name', filterable: true,},
    { text: 'City', value: 'City', filterable: true,},
    { text: 'Price', value: 'Price', filterable: true },
    { text: 'Order Count', value: 'OrdersNumber', filterable: false,},
    { text: 'Customer Number', value: 'CustomerNumber', filterable: false,},
    //{ text: 'Description', value: 'Description', sortable: true },
     { text: 'Status', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const AddressListTable = ref([{}])
  const search = ref('')
  const BranchId = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const totalAddressListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const addressTotalLocal = ref([])
  const loading = ref(false)
  const groupByCategory = ref ({})
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchAddressess = () => {
    Get({ BranchId: BranchId.value })
      .then(response => {
       //if (res)
        // handle success
        AddressListTable.value = response
        totalAddressListTable.value = response.length
        addressTotalLocal.value = response.length
        // remove loading state
        let b = JSON.parse(JSON.stringify(AddressListTable.value))
        AddressListTable.value.map( (val, index, arr) => {
          let orders = val.OrdersNumber
          orders.filter(x => x.BranchId== BranchId.value)
          let orArr= orders.reduce((prev, cur) => {

            const  {CustomerId}  = cur;
            prev[CustomerId] = prev[CustomerId] ?? [];
            prev[CustomerId].push(cur);
            return prev;
          }, {});
          arr[index].CustomerNumber = Object.keys(orArr).length
          // console.log('order num',val)
        } )
        loading.value = false
      })
      .catch(error => {
        // remove loading state
        loading.value = false
        console.log(error)
      })
  }
  onMounted( () => {
    fetchAddressess()
  })
  watch([statusFilter, options], () => {
    loading.value = true
    fetchAddressess()
  })
  return {
    groupByCategory,
    AddressListTable,
    tableColumns,
    search,
    BranchId,
    statusFilter,
    totalAddressListTable,
    options,
    addressTotalLocal,
    fetchAddressess,
    loading,
  }
}
