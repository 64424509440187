import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"right":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddActive = !_vm.isAddActive}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Address")])],1)],1),_c(VCol,{attrs:{"cols":"2"}})],1),_c('add-address',{attrs:{"BranchId":parseInt(_vm.$router.currentRoute.params.id)},on:{"refetch-data":_vm.fetchAddressess},model:{value:(_vm.isAddActive),callback:function ($$v) {_vm.isAddActive=$$v},expression:"isAddActive"}}),_c('address-delete',{attrs:{"Id":_vm.addressTemp.Id},on:{"refetch-data":_vm.fetchAddressess},model:{value:(_vm.isDialogDelete),callback:function ($$v) {_vm.isDialogDelete=$$v},expression:"isDialogDelete"}}),_c('address-edit',{attrs:{"Address":_vm.addressTemp},on:{"refetch-data":_vm.fetchAddressess},model:{value:(_vm.isDialogEdit),callback:function ($$v) {_vm.isDialogEdit=$$v},expression:"isDialogEdit"}}),_c('app-card-actions',{on:{"refresh":_vm.fetchAddressess}},[_c('template',{slot:"title"},[_vm._v(" Address List ")]),_c(VCardText,[_c(VTextField,{staticClass:"Orders-List-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.AddressListTable,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.Id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.Id)+" ")]}},{key:"item.Price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.Price != undefined? item.Price.toFixed(3) : 0)+" JD")])])])]}},{key:"item.OrdersNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.OrdersNumber != undefined? item.OrdersNumber.length : 0))])])])]}},{key:"item.CustomerNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.CustomerNumber))])])])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.$store.state.status['addresses'].find(function (obj) {
                  return obj.value == item.Status
                }).variant || 'primary') + "--text"),attrs:{"small":"","color":_vm.$store.state.status['addresses'].find(function (obj) {
                  return obj.value == item.Status
                }).variant || 'primary'}},[_vm._v(" "+_vm._s(item.Status)+" ")])]}},{key:"item.Actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"demo-space-x"},[(item.Status!='inactive')?_c(VBtn,{attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();;(_vm.isDialogDelete = !_vm.isDialogDelete), (_vm.addressTemp = Object.assign({}, item))}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1):_vm._e(),_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();;(_vm.isDialogEdit = !_vm.isDialogEdit), (_vm.addressTemp = Object.assign({}, item))}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)],1)]}}],null,true)})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }