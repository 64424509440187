<template>
  <v-navigation-drawer
    :value="isAddActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New </span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="AddressData.Name"
            outlined
            dense
            :rules="[validators.required]"
            label="Name"
            placeholder="Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <VcJordanCitiySelect class="mb-6" :City="AddressData.City" v-model="AddressData.City"></VcJordanCitiySelect>
          <v-text-field
            type="Number"
            v-model="AddressData.Price"
            :rules="[validators.required]"
            label="Price"
            prefix="$"
            @focus="$event.target.select()"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="AddressData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['addresses']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>

          <v-text-field
            v-model="AddressData.Description"
            outlined
            dense
            label="Description"
            placeholder="Description"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <br />
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetAddressData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import VcJordanCitiySelect from '@/components/vc-select/VcJordanCitiySelect.vue'
import { Create } from '@core/api/Address'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  components: { VcJordanCitiySelect },
  model: {
    prop: 'isAddActive',
    event: 'update:is-add-active',
  },
  props: {
    isAddActive: {
      type: Boolean,
      required: true,
    },
    BranchId: { type: Number, required: false },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      BranchId: props.BranchId,
      Name: '',
      City: 'Amman',
      Price: 0.0,
      Description: '',
      Status: '',
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const AddressData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetAddressData = () => {
      AddressData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-add-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        Create(AddressData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-active', false)
        })
        resetAddressData()
      } else {
        validate()
      }
    }

    return {
      resetAddressData,
      form,
      onSubmit,
      AddressData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
