<template>
  <v-dialog :value="isDialogEdit" max-width="750px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Address : {{ Address.Id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="AddressData.Name"
              outlined
              dense
              :rules="[validators.required]"
              label="Name"
              placeholder="Name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <VcJordanCitiySelect class="mb-6" :City="AddressData.City" v-model="AddressData.City"></VcJordanCitiySelect>

            <v-text-field
              type="Number"
              v-model="AddressData.Price"
              label="Price"
              prefix="$"
              outlined
              dense
              @focus="$event.target.select()"
              class="mb-6"
            ></v-text-field>
            <v-select
              v-model="AddressData.Status"
              :rules="[validators.required]"
              label="Status"
              :items="$store.state.status['addresses']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              class="mb-6"
            ></v-select>
            <v-text-field
              v-model="AddressData.Description"
              label="Description"
              placeholder="Description"
              hide-details="auto"
              outlined
              dense
              class="mb-6"
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import VcJordanCitiySelect from '@/components/vc-select/VcJordanCitiySelect.vue'
import { Edit } from '@core/api/Address'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  components: { VcJordanCitiySelect },
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    Address: { type: Object, required: true },
  },
   watch: {
     Address(New) {
       this.AddressData = New
     },
   },

  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      BranchId: props.BranchId,
      Name: '',
      City: '',
      Price: 0,
      Description: '',
      Status: '',
    }
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const AddressData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetAddressData = () => {
      AddressData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-dialog-edit', false)
    }
    const onSubmit = () => {
      //if (valid.value) {
      if (AddressData.value.Price != null && AddressData.value.Name != null) {
        delete AddressData.value.OrdersNumber
        Edit(AddressData.value).then(() => {
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
        })
        resetAddressData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetAddressData()
    }
    return {
      resetAddressData,
      form,
      onSubmit,
      AddressData,
      valid,
      validate,
      mdiPlus,
      close,
      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },

}
</script>
